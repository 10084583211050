<template>
  <div>
    <div class="d-flex align-items-center justify-content-between mb-2">
      <section class="col-4">
        <label>Search</label>
        <b-input-group>
          <b-form-input
            v-model="filters.search"
            class="d-inline-block"
            placeholder="Search by comment or user..."
            @keydown.enter="getMoreComments(1)"
          />
          <b-input-group-append>
            <b-button @click="getMoreComments(1)">Search</b-button>
          </b-input-group-append>
        </b-input-group>
      </section>

      <section class="col-2 d-flex justify-content-end align-items-center">
        <b-button @click="clearFilters">Clear filters</b-button>
      </section>
    </div>

    <b-tabs v-model="status">
      <b-tab :title="`Pending`" active @click="setStatus('Pending')" />
      <b-tab :title="`Approved`" @click="setStatus('Approved')" />
      <b-tab :title="`Rejected`" @click="setStatus('Rejected')" />
      <b-tab :title="`Deleted`" @click="setStatus('Deleted')" />
    </b-tabs>

    <b-table
      v-if="!loading"
      :fields="headers"
      :items="comments"
      class="data-table"
      hover
    >
      <template #cell(owner)="data">
        <router-link
          v-if="$can('Read', 'Owner')"
          :to="`/owners/owner/${data.item.owner.uid}`"
          class="font-weight-bold d-block text-nowrap"
        >
          {{ data.item.owner.identifier }}
        </router-link>
      </template>
      <template #cell(date)="data">
        <!-- Supports old version of meta data-->
        <span>
          {{ data.item.date | formatDate }}
        </span>
      </template>
      <template #cell(actions)="data">
        <router-link
          :to="{ name: 'comment-details', params: { id: data.item.id } }"
          class="font-weight-bold d-block text-nowrap"
        >
          View comment
        </router-link>
      </template>
    </b-table>
    <b-row>
      <b-col v-if="totalCount > filters.page_size" class="my-1" md="6">
        <b-pagination
          v-model="currentPage"
          :per-page="filters.page_size"
          :total-rows="totalCount"
          class="my-0 mx-1"
          @change="getMoreComments"
        />
      </b-col>
      <p v-if="totalCount === 0" class="col-6 ml-2 my-1">No records</p>
    </b-row>
  </div>
</template>

<script>
import TheHubService from "@/services/TheHubService";

export default {
  name: "CommentsTable",
  props: {
    article: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      headers: [
        {
          key: "owner.identifier",
          label: "Device Owner",
        },
        {
          key: "comment",
          label: "Comment",
          class: "col-6",
        },
        {
          key: "date",
          label: "Date",
        },
        this.$can("Read", "TheHub.Likes") && {
          key: "like_count",
          label: "Likes",
        },
        {
          key: "actions",
          label: "",
          class: "text-right",
        },
      ],
      loading: false,
      filters: {
        page_size: 10,
        page: 0,
        status: "Pending",
        search: "",
        order: "DESC",
        article_id: null,
      },
      comments: [],
      selectedStatus: "Pending",
      currentPage: 1,
      totalCount: 0,
      status: 1,
    };
  },
  mounted() {
    if (this.$props.article) {
      this.filters.article_id = this.$props.article.id;
    }

    this.fetchArticleComments();
  },
  methods: {
    getMoreComments(page) {
      this.filters.page = page - 1;
      this.currentPage = page;
      this.fetchArticleComments();
    },
    async fetchArticleComments() {
      this.loading = true;
      try {
        const { data } = await TheHubService.listArticleComments(this.filters);
        this.comments = [...data.data];

        this.filters.page = data.meta.page;
        this.filters.page_size = data.meta.page_size;
        this.totalCount = data.meta.total_count;
      } catch (err) {
        const res = err.response;
        let errorText = "Could not get comments, please refresh and try again";

        if (res && res.data.error) {
          errorText = res.data.error;
        }
        this.$toast.error(errorText, {
          toastClassName: ["toast-std", "warning-toast"],
        });
      } finally {
        this.loading = false;
      }
    },
    clearFilters() {
      this.filters = {
        page_size: 10,
        page: 0,
        search: "",
        status: this.selectedStatus,
        order: "DESC",
        article_id: null,
      };
      if (this.$props.article) {
        this.filters.article_id = this.$props.article.id;
      }

      this.fetchArticleComments();
    },
    setStatus(status) {
      this.selectedStatus = status;
      this.filters.status = status;
      this.filters.page = 0;
      this.currentPage = 1;
      this.fetchArticleComments();
    },
  },
};
</script>

<style lang="scss" scoped></style>
