<template>
  <div class="activity-page">
    <b-card class="" title="Comments">
      <b-card-text>
        This page shows all comments for articles published on TheHub.
      </b-card-text>
    </b-card>

    <b-card>
      <comments-table />
    </b-card>
  </div>
</template>

<script>
import CommentsTable from "@/views/theHub/sections/CommentsTable.vue";

export default {
  name: "Comments",
  components: { CommentsTable },
};
</script>

<style lang="scss" scoped></style>
